import { ethers } from 'ethers'
import { ERC20_ABI, UNISWAP_V2_FACTORY_ABI, UNISWAP_V2_PAIR_ABI, UNISWAP_V2_ROUTER_ABI } from './abi'
import { InvestorPlatformClient } from './client'

export class EtherPairPriceFeed {
  private readonly router: ethers.Contract
  private factory?: ethers.Contract
  private weth?: string

  constructor(
    private readonly routerAddress: string,
    private readonly usdcAddress: string,
    private readonly provider: ethers.providers.Provider,
    private readonly chainId: number
  ) {
    this.router = new ethers.Contract(this.routerAddress, UNISWAP_V2_ROUTER_ABI, this.provider)
  }

  async getPriceUsdBatch(tokens: string[], client: InvestorPlatformClient) {
    const factory = await this.getFactory()
    const weth = await this.getWeth()
    const promises = []
    for (const token of tokens) {
      promises.push(this.getPriceEther(token, factory, weth))
    }

    const ethPrice = await this.getEtherPrice(client)
    const tokenEthPrices = await Promise.all(promises)
    return tokenEthPrices.map(p => p * ethPrice)
  }

  async getEtherPrice(client: InvestorPlatformClient) {
    const { ethPrice } = await client.getEtherPrice(this.chainId)
    return ethPrice
  }

  private async getPriceEther(
    token: string,
    factory: ethers.Contract,
    weth: string
  ) {
    const pairAddress = await factory.getPair(weth, token)
    if (pairAddress != ethers.constants.AddressZero) {
      const ethPerToken = await this.getExchangeRate(token, weth, pairAddress)
      return ethPerToken
    }

    const [usdcEthPairAddress, usdcTokenPairAddress] = await Promise.all([
      factory.getPair(weth, this.usdcAddress),
      factory.getPair(this.usdcAddress, token)
    ])
    if (
      usdcEthPairAddress == ethers.constants.AddressZero ||
      usdcTokenPairAddress == ethers.constants.AddressZero
    ) {
      return 0
    }

    const ethPerUsdc = await this.getExchangeRate(this.usdcAddress, weth, usdcEthPairAddress)
    const usdcPerToken = await this.getExchangeRate(token, this.usdcAddress, usdcTokenPairAddress)
    return ethPerUsdc * usdcPerToken
  }

  private async getExchangeRate(
    baseToken: string,
    quoteToken: string,
    pairAddress: string,
  ) {
    const pair = new ethers.Contract(pairAddress, UNISWAP_V2_PAIR_ABI, this.provider)
    const quoteTokenContract = new ethers.Contract(quoteToken, ERC20_ABI, this.provider)
    const baseTokenContract = new ethers.Contract(baseToken, ERC20_ABI, this.provider)
    const [
      { _reserve0, _reserve1 },
      token0,
      quoteDecimals,
      baseDecimals
    ] = await Promise.all([
      pair.getReserves(),
      pair.token0(),
      quoteTokenContract.decimals(),
      baseTokenContract.decimals()
    ])

    const reserveQuote: ethers.BigNumber = token0 == quoteToken ? _reserve0 : _reserve1
    const reserveBase: ethers.BigNumber = token0 == quoteToken ? _reserve1 : _reserve0
    const amountQuoteFloat = Number(ethers.utils.formatUnits(reserveQuote, quoteDecimals))
    const amountBaseFloat = Number(ethers.utils.formatUnits(reserveBase, baseDecimals))
    return amountQuoteFloat / amountBaseFloat
  }

  private async getFactory() {
    if (!this.factory) {
      const factoryAddress = await this.router.factory()
      this.factory = new ethers.Contract(factoryAddress, UNISWAP_V2_FACTORY_ABI, this.provider)
    }

    return this.factory
  }

  private async getWeth() {
    if (!this.weth) {
      this.weth = <string> await this.router.WETH()
    }

    return this.weth
  }
}