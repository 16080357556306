
















































































































































































































































































































































import { InvestorPlatformClient, OrderType } from '@/client'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ethers } from 'ethers'
import { useStore } from '@/store'
import { delay } from '@/common'
import assert from 'assert'
import { ChainInfo, CHAINS, getTxHref } from '@/chains'
import { DEXES } from '@/dexes'
import { EtherPairPriceFeed } from '@/ether-pair-price-feed'
import { List } from 'linq-collections'

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

@Component({
  filters: {
    outputUsd(n: number) {
      return n.toFixed(4)
    }
  }
})
export default class Dashboard extends Vue {
  tableHeaders: any[] = [
    { text: 'Id', value: 'id', sortable: false},
    { text: 'Asset', value: 'asset' },
    { text: 'Symbol', value: 'symbol' },
    { text: 'Chain', value: 'chain' },
    { text: 'Balance', value: 'balance' },
    { text: 'Value', value: 'valueUsd' },
    { text: 'Type', value: 'orderType' },
    { text: '', value: 'deposit', sortable: false },
    { text: '', value: 'withdraw', sortable: false },
    // { text: '', value: 'swap', sortable: false },
    { text: '', value: 'chart', sortable: false }
  ]
  assets: any[] = []
  depositActionDrawer: boolean = false
  withdrawActionDrawer: boolean = false
  swapActionDrawer: boolean = false
  currentItem: any = null
  depositAddress: string | null = null
  withdrawAddress = ''
  estimatedTxFee: ethers.BigNumber | null = null
  withdrawAmount = ''
  isAssetsLoading = true
  isWithdrawLoading = false
  active = true
  costBasis: { depositCostBasis: number, withdrawalCostBasis: number } | null = null

  get displayEstimatedTxFee() {
    return this.estimatedTxFee != null ? ethers.utils.formatEther(this.estimatedTxFee) : null
  }

  get currentChain() {
    return this.currentItem ? CHAINS[this.currentItem.chainId] : Object.values(CHAINS)[0]
  }

  get totalWithdrawAmount() {
    const withdrawAmount = ethers.utils.parseEther(this.withdrawAmount != '' ? this.withdrawAmount : '0')
    const txFee = this.estimatedTxFee != null ? this.estimatedTxFee : ethers.BigNumber.from(0)
    let totalWithdrawBn = withdrawAmount.add(txFee)
    const currentBalance = this.currentItem ? ethers.utils.parseEther(this.currentItem.balance) : null
    if (currentBalance && totalWithdrawBn.gt(currentBalance)) {
      totalWithdrawBn = currentBalance
    }
    return ethers.utils.formatEther(totalWithdrawBn)
  }

  get totalBalanceUsd() {
    return new List(this.assets).sum(a => a.valueUsd)
  }

  get netProfitLoss() {
    if (!this.costBasis) {
      return 0
    }

    return this.totalBalanceUsd + this.costBasis.withdrawalCostBasis - this.costBasis.depositCostBasis
  }

  get netProfitLossPercent() {
    if (this.netProfitLoss == 0 || this.totalBalanceUsd == 0) {
      return 0
    }

    const pnlPercent = 100 * this.netProfitLoss / this.totalBalanceUsd
    return pnlPercent.toLocaleString('en-us', { maximumFractionDigits: 2 })
  }

  get store() {
    return useStore()
  }

  getChainColor(chain: string) {
    if (chain == 'ETH') {
      return { background: '#6C5DD3', foreground: 'white' }
    } else if (chain == 'BSC') {
      return { background: '#F3BA2F', foreground: 'black' }
    }

    return { background: '', foreground: '' }
  }

  async withdraw() {
    if (!this.currentItem) {
      return
    }

    this.isWithdrawLoading = true
    try {
      const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
      const amount = ethers.utils.parseUnits(this.withdrawAmount, this.currentItem.decimals)
      const txHistory = await client.withdraw(
        this.currentItem.walletId,
        this.currentItem.holdingId,
        this.withdrawAddress,
        amount.toString()
      )
      console.log(txHistory)

      const href = getTxHref(txHistory.transaction_hash, txHistory.chain_id)
      this.store.pushNotification('Withdraw complete', href)
    } finally {
      this.isWithdrawLoading = false
    }
  }

  async onDepositActionDrawerTransition(transitionEvent: TransitionEvent) {
    if (transitionEvent.propertyName != 'visibility' || !this.depositActionDrawer) {
      return
    }

    assert(this.currentItem != null)
    this.depositAddress = null
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    const wallet = await client.getWalletByChainId(this.currentItem.chainId)
    this.depositAddress = wallet.deposit_address
  }

  async mounted() {
    while (this.active) {
      try {
        await this.sync()
      } catch (e) {
        console.error(e)
        Vue.config.errorHandler(e as any, this.$root, '')
      } finally {
        await delay(5000)
      }
    }
  }

  async sync() {
    const client = InvestorPlatformClient.getFromStoredToken(process.env.VUE_APP_API_ENDPOINT!)
    const holdings = await client.getAllHoldings()
    const assets = []
    for (const holding of holdings) {
      let actions = ['chart']
      if (!holding.contract_address) {
        actions = ['deposit', 'withdraw', ...actions]
      }
      assets.push({
        id: holding.contract_address != null ? holding.id : null,
        asset: holding.name,
        symbol: holding.symbol,
        chain: CHAINS[holding.chain_id].shortName,
        chainId: holding.chain_id,
        balance: ethers.utils.formatUnits(holding.balance, holding.decimals),
        balanceBn: ethers.utils.parseUnits(holding.balance, holding.decimals),
        actions: actions,
        holdingId: holding.id,
        walletId: holding.wallet_id,
        dex: holding.dex,
        address: holding.contract_address,
        orderType: OrderType[holding.order_type],
        valueUsd: 0
      })
    }
    this.costBasis = await client.getCostBasis()

    // TODO: sync price feeds
    const priceFeedsToQuery = new Map<EtherPairPriceFeed, string[]>()
    for (const asset of assets) {
      const dex = DEXES.find(d => d.chainId == asset.chainId && d.routerAddress == asset.dex)
      if (!dex) {
        continue
      }
      if (!dex.priceFeed) {
        continue
      }
      if (!asset.address) {
        continue
      }

      if (!priceFeedsToQuery.has(dex.priceFeed)) {
        priceFeedsToQuery.set(dex.priceFeed, [])
      }
      priceFeedsToQuery.get(dex.priceFeed)!.push(asset.address)
    }

    // populate token values
    const entries = Array.from(priceFeedsToQuery.entries())
    const promises = []
    const priceBatches: [string, {[t: string]: number}][] = []
    for (const [priceFeed, tokens] of entries) {
      const dex = DEXES.find(d => d.priceFeed === priceFeed)!
      promises.push(priceFeed.getPriceUsdBatch(tokens, client).then(priceBatch => {
        const priceMap: {[t: string]: number} = {}
        for (let i = 0; i < priceBatch.length; i++) {
          priceMap[tokens[i]] = priceBatch[i]
        }
        priceBatches.push([dex.routerAddress, priceMap])
      }))
    }
    await Promise.all(promises)

    for (const [routerAddress, priceMap] of priceBatches) {
      const assetsBatch = assets.filter(a => priceMap[a.address] != null && a.dex == routerAddress)
      for (const asset of assetsBatch) {
        asset.valueUsd = Number(asset.balance) * priceMap[asset.address]
      }
    }

    // populate ether values
    const chainIdGroups = new List(assets)
      .where(a => a.address === null)
      .groupBy(a => a.chainId)
      .toArray()
    const etherPricePromises = []
    for (const chainIdGroup of chainIdGroups) {
      const assets = chainIdGroup.value.toArray()
      const dex = DEXES.find(d => d.chainId == chainIdGroup.key && d.priceFeed != null)
      if (!dex) {
        continue
      }

      const promise = dex.priceFeed!.getEtherPrice(client).then(p => {
        for (const asset of assets) {
          asset.valueUsd = Number(asset.balance) * p
        }
      })
      etherPricePromises.push(promise)
    }
    await Promise.all(etherPricePromises)
    this.assets = assets
    if (this.currentItem) {
      this.currentItem = this.assets.find(a => a.holdingId == this.currentItem.holdingId)
    }
    this.isAssetsLoading = false

    if (this.currentItem) {
      const currentChain = CHAINS[this.currentItem.chainId]
      const provider = new ethers.providers.StaticJsonRpcProvider(currentChain.rpcUrl)
      const [gasAmount, gasPrice] = await Promise.all([
        provider.estimateGas({ to: ZERO_ADDRESS }),
        provider.getGasPrice()
      ])
      this.estimatedTxFee = gasAmount.mul(gasPrice)
    }
  }

  copyText(text: string) {
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.top = '0'
    textarea.style.left = '0'
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)

    textarea.focus()
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  beforeDestroy() {
    this.active = false
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    this.active = false
    next()
  }

  @Watch('currentChain')
  onChainChanged(val: ChainInfo, newVal: ChainInfo) {
    if (val.chainId == newVal.chainId) {
      return
    }

    this.estimatedTxFee = null
  }
}
