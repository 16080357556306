import { ethers } from "ethers";
import { EtherPairPriceFeed } from "./ether-pair-price-feed";
import { CHAINS } from './chains'

export const DEXES = [{
  name: 'Uniswap V2',
  routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  chainId: 1,
  priceFeed: new EtherPairPriceFeed(
    '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    new ethers.providers.StaticJsonRpcProvider(CHAINS[1].rpcUrl),
    1
  )
}, {
  name: 'Uniswap V2',
  routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  chainId: 3
}, {
  name: 'Uniswap V2',
  routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  chainId: 5,
  priceFeed: new EtherPairPriceFeed(
    '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    '0x6Fb5ef893d44F4f88026430d82d4ef269543cB23', // usdc address
    new ethers.providers.StaticJsonRpcProvider(CHAINS[5].rpcUrl),
    5
  )
}]