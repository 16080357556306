export type ChainInfo = {
  chainId: number,
  shortName: string,
  rpcUrl: string,
  currencySymbol: string,
  scannerUrl: string
}

export const CHAINS: {[chainId: number]: ChainInfo} = {
  1: {
    chainId: 1,
    shortName: 'ETH',
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    currencySymbol: 'ETH',
    scannerUrl: 'https://etherscan.io'
  },
  3: {
    chainId: 3,
    shortName: 'ROP',
    rpcUrl: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    currencySymbol: 'ETH',
    scannerUrl: 'https://ropsten.etherscan.io'
  },
  5: {
    chainId: 5,
    shortName: 'GTH',
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    currencySymbol: 'GTH',
    scannerUrl: 'https://goerli.etherscan.io'
  },
  56: {
    chainId: 56,
    shortName: 'BSC',
    rpcUrl: 'https://bsc-dataseed1.defibit.io/',
    currencySymbol: 'BNB',
    scannerUrl: 'https://bscscan.com'
  },
  1337: {
    chainId: 1337,
    shortName: 'TEST',
    rpcUrl: 'http://localhost:8545',
    currencySymbol: 'ETH',
    scannerUrl: 'http://localhost/scanner'
  }
}

export function getTxHref(txId: string, chainId: number) {
  const chainInfo = CHAINS[chainId]
  if (!chainInfo) {
    throw new Error(`Unknown chainId ${chainId}`)
  }
  
  return `${chainInfo.scannerUrl}/tx/${txId}`
}